import { APLICATION_ROLES } from 'enums/roles';
import { LATAM, EUROPE, LATAM_ES } from 'enums/regions';

const europeAccess = EUROPE.filter((country) => country !== 'NL');
const latamAccess = LATAM.filter((country) =>
  [
    'AR',
    'BO',
    'CE',
    'CL',
    'CO',
    'CR',
    'DO',
    'EC',
    'GT',
    'HN',
    'JM',
    'MX',
    'PA',
    'PE',
    'PY',
    'SV',
    'UY',
    'BR',
  ].includes(country),
);

export default {
  header: {
    self: {},
    items: [
      {
        title: 'Inicio',
        root: true,
        alignment: 'left',
        page: 'dashboard',
      },
      {
        title: 'Mis Clientes',
        root: true,
        alignment: 'left',
        page: 'distributor/client',
        access: [APLICATION_ROLES.Distributor, APLICATION_ROLES.Segvel],
      },
      {
        title: 'Instaladores',
        root: true,
        alignment: 'left',
        page: '3m/installer',
        access: [APLICATION_ROLES.Admin, APLICATION_ROLES.Segvel],
      },
      {
        title: 'Distribuidores',
        root: true,
        alignment: 'left',
        page: '3m/distributor',
        access: [APLICATION_ROLES.Admin, APLICATION_ROLES.Segvel],
      },
      {
        title: 'Automóvil',
        root: true,
        alignment: 'left',
        auto: true,
        submenu: [
          {
            title: 'Mis instalaciones',
            page: 'installer/car',
            access: [APLICATION_ROLES.Installer, APLICATION_ROLES.SegvelInst],
            auto: true,
          },
          {
            title: 'Nueva Garantía - Pelicula para Ventana',
            page: 'installer/car/new',
            access: [APLICATION_ROLES.Installer, APLICATION_ROLES.SegvelInst],
            auto: true,
          },
          {
            title: 'Nueva Garantía - PPF',
            page: 'installer/car/ppfNew',
            access: [APLICATION_ROLES.Installer, !APLICATION_ROLES.Admin],
            auto: true,
          },
          {
            title: 'Nueva Garantía - Auto Wrap',
            page: 'installer/car/wrappingNew',
            access: [APLICATION_ROLES.Installer, !APLICATION_ROLES.Admin],
            auto: true,
          },
          {
            title: 'Certificados de Garantias Emitidos',
            page: 'distributor/car',
            access: [APLICATION_ROLES.Distributor, APLICATION_ROLES.Segvel],
            auto: true,
          },
          {
            title: 'Certificados de Garantias Emitidos',
            page: '3m/car',
            access: [APLICATION_ROLES.Admin],
            auto: true,
          },
        ],
      },
      {
        title: 'Arquitectura',
        building: true,
        root: true,
        alignment: 'left',
        submenu: [
          {
            title: 'Mis Proyectos',
            page: 'installer/building',
            access: [APLICATION_ROLES.Installer, APLICATION_ROLES.SegvelInst],
            building: true,
          },
          {
            title: 'Nuevo Proyecto Comercial',
            page: 'installer/building/new/comercial',
            access: [APLICATION_ROLES.Installer],
            country: LATAM,
            building: true,
          },
          {
            title: 'Nuevo Proyecto Residencial',
            page: 'installer/building/new/residential',
            access: [APLICATION_ROLES.Installer],
            building: true,
          },
          {
            title: 'Proyectos Registrados',
            page: 'distributor/building',
            access: [APLICATION_ROLES.Distributor, APLICATION_ROLES.Segvel],
            building: true,
          },
          {
            title: 'Proyectos Registrados',
            page: '3m/building',
            access: [APLICATION_ROLES.Admin],
            building: true,
          },
        ],
      },
      {
        title: 'Acabados de Arquitectura',

        dinoc: true,
        fasara: true,
        root: true,
        alignment: 'left',
        submenu: [
          {
            title: 'Mis Proyectos',
            page: 'installer/arqFinishes',
            access: [APLICATION_ROLES.Installer],
            dinoc: true,
            fasara: true,
          },
          {
            title: 'Proyectos Registrados',
            page: 'distributor/arqFinishes',
            access: [APLICATION_ROLES.Distributor],
            dinoc: true,
            fasara: true,
          },
          {
            title: 'Proyectos Registrados',
            page: '3m/arqFinishes',
            access: [APLICATION_ROLES.Admin],
            dinoc: true,
            fasara: true,
          },
          {
            title: '3M™ DI-NOC™',
            access: [APLICATION_ROLES.Installer],
            dinoc: true,
            alignment: 'right',
            submenu: [
              {
                title: 'Nuevo Proyecto Comercial',
                page: 'installer/arqFinishes/dinoc/new/large',
                access: [APLICATION_ROLES.Installer],
                country: LATAM,
                dinoc: true,
              },
              {
                title: 'Nuevo Proyecto',
                page: 'installer/arqFinishes/dinoc/new/small',
                access: [APLICATION_ROLES.Installer],
                dinoc: true,
              },
            ],
          },
          {
            title: 'Acabado de vidrio',
            access: [APLICATION_ROLES.Installer],
            fasara: true,
            alignment: 'right',
            submenu: [
              {
                title: 'Nuevo Proyecto Comercial',
                page: 'installer/arqFinishes/fasara/new/large',
                access: [APLICATION_ROLES.Installer],
                country: LATAM,
                fasara: true,
              },
              {
                title: 'Nuevo Proyecto',
                page: 'installer/arqFinishes/fasara/new/small',
                access: [APLICATION_ROLES.Installer],
                fasara: true,
              },
            ],
          },
        ],
      },
      {
        title: 'Productos',
        root: true,
        alignment: 'left',
        page: 'product',
        access: LATAM,
        submenu: [
          {
            title: 'Automóvil',
            access: LATAM,
            page: 'product_car',
          },
          {
            title: 'Arquitectura',
            access: LATAM,
            page: 'product_arq',
          },
        ],
      },
      {
        title: 'Usuarios',
        root: true,
        alignment: 'left',
        page: 'backoffice/users',
        access: [APLICATION_ROLES.Owner, APLICATION_ROLES.MMMAdm],
      },
      /*{
        title: 'Contacto',
        root: true,
        alignment: 'left',
        page: 'contact',
        access: [
          APLICATION_ROLES.Distributor,
          APLICATION_ROLES.Admin,
          APLICATION_ROLES.Installer,
        ],
      },*/
    ],
  },
  aside: {
    self: {},
    items: [
      {
        title: 'Inicio',
        root: true,
        alignment: 'left',
        page: 'dashboard',
      },
      {
        title: 'Mis Clientes',
        root: true,
        alignment: 'left',
        page: 'distributor/client',
        access: [APLICATION_ROLES.Distributor, APLICATION_ROLES.Segvel],
      },
      {
        title: 'Instaladores',
        root: true,
        alignment: 'left',
        page: '3m/installer',
        access: [APLICATION_ROLES.Admin],
      },
      {
        title: 'Distribuidores',
        root: true,
        alignment: 'left',
        page: '3m/distributor',
        access: [APLICATION_ROLES.Admin],
      },
      {
        title: 'Automóvil',
        root: true,
        alignment: 'left',
        auto: true,
        submenu: [
          {
            title: 'Mis instalaciones',
            page: 'installer/car',
            access: [APLICATION_ROLES.Installer, APLICATION_ROLES.SegvelInst],
            auto: true,
          },
          {
            title: 'Nuevo Cliente',
            page: 'installer/car/new',
            access: [APLICATION_ROLES.Installer],
            auto: true,
          },
          {
            title: 'Certificados de Garantias Emitidos',
            page: 'distributor/car',
            access: [APLICATION_ROLES.Distributor, APLICATION_ROLES.Segvel],
            auto: true,
          },
          {
            title: 'Certificados de Garantias Emitidos',
            page: '3m/car',
            access: [APLICATION_ROLES.Admin],
            auto: true,
          },
        ],
      },
      {
        title: 'Arquitectura',
        root: true,
        alignment: 'left',
        building: true,
        submenu: [
          {
            title: 'Mis Proyectos',
            page: 'installer/building',
            access: [APLICATION_ROLES.Installer, APLICATION_ROLES.SegvelInst],
            building: true,
          },
          {
            title: 'Nuevo Proyecto Comercial',
            page: 'installer/building/new/comercial',
            access: [APLICATION_ROLES.Installer],
            country: LATAM,
            building: true,
          },
          {
            title: 'Nuevo Proyecto Residencial',
            page: 'installer/building/new/residential',
            access: [APLICATION_ROLES.Installer],
            building: true,
          },
          {
            title: 'Proyectos Registrados',
            page: 'distributor/building',
            access: [APLICATION_ROLES.Distributor, APLICATION_ROLES.Segvel],
            building: true,
          },
          {
            title: 'Proyectos Registrados',
            page: '3m/building',
            access: [APLICATION_ROLES.Admin],
            building: true,
          },
        ],
      },
      // {
      //   title: 'Productos',
      //   root: true,
      //   alignment: 'left',
      //   page: 'product',
      // },
      {
        title: 'Contacto',
        root: true,
        alignment: 'left',
        page: 'contact',
      },
    ],
  },
};
