import * as Yup from 'yup';
import { EUROPE } from 'enums/regions';

export default (intl, country) => {
  const isEuropeCountry = EUROPE.includes(country);
  Yup.object().shape({
    project: Yup.object().shape({
      name: Yup.string().required(
        intl.formatMessage({ id: 'Debe ingresar un nombre' }),
      ),
      postalCode: Yup.string().required(
        intl.formatMessage({ id: 'Debe ingresar un código postal' }),
      ),
      type: Yup.string()
        .required(intl.formatMessage({ id: 'Debe seleccionar un tipo' }))
        .typeError(intl.formatMessage({ id: 'Debe seleccionar un tipo' })),
      needCase: Yup.string()
        .when([], {
          is: () => isEuropeCountry,
          then: Yup.string().required(
            intl.formatMessage({ id: 'Debe seleccionar una opción' }),
          ),
          otherwise: Yup.string().notRequired(),
        })
        .typeError(intl.formatMessage({ id: 'Debe seleccionar una opción' })),

      /*state: Yup.string()
        .required(intl.formatMessage({ id: 'Debe seleccionar una provincia' }))
        .typeError(
          intl.formatMessage({ id: 'Debe seleccionar una provincia' }),
        ),    */
    }),
  });
};
