import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import axios from 'axios';
import { useIntl } from 'react-intl';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { EUROPE } from 'enums/regions';
import { DropzoneDialog } from 'material-ui-dropzone';
import { fileName } from 'views/installer/car/clientList/table';

const API_URL = window.env.API_URL;
const ACCESS_TOKEN = window.env.ACCESS_TOKEN;

const handleDownloadFile = () => {
  const endUserFileUrl = localStorage.getItem('endUserFileUrl');
  if (endUserFileUrl) {
    window.open(endUserFileUrl, '_blank');
  } else {
    console.error('End user file URL is not available in localStorage.');
  }
};
const handleSave = async (files) => {
  if (files.length > 0) {
    const file = files[0];
    const originalFileName = file.name;
    const newFileName = `end_user-${originalFileName}`;

    const blob = new Blob([file], { type: file.type });
    const newFile = new File([blob], newFileName, { type: file.type });

    const data = new FormData();
    data.append('file', newFile);

    try {
      const url = `${API_URL}/bulk/upload`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`,
        },
      });

      if (response.status === 200) {
        localStorage.setItem('endUserFileUrl', response.data.url);
        console.log('Archivo subido con éxito:', response.data.url);
      } else {
        throw new Error('Error en la respuesta del servidor');
      }
    } catch (error) {
      console.error('Error al subir el archivo:', error);
    }
  }
};

export const Glass = ({ values }) => {
  const intl = useIntl();
  const [selectedFile, setSelectedFile] = useState(null);
  const isEuropeCountry = EUROPE.includes(values.data.country);

  // Manejar selección del archivo
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // Llamar a la función de subir archivo
  const handleUpload = () => {
    if (selectedFile) {
      handleSave([selectedFile]);
    }
  };

  const [open, setOpen] = React.useState(false);

  return (
    <>
      <div className="row">
        <div className="col-12 ViewOrEdit">
          <div>
            <strong>
              <FormattedMessage id="Tipo de vidrio:" />
            </strong>
            <span>
              <FormattedMessage id={get(values, 'data.glass', '-')} />
            </span>
          </div>
          <div className="kt-space-20" />
          <div>
            <strong>
              <FormattedMessage id="Realizó el Glass Checklist:" />
            </strong>
            <span>
              <FormattedMessage id={get(values, 'data.Checklist', '-')} />
            </span>
          </div>
          <div className="kt-space-20" />
          {isEuropeCountry && (
            <div>
              {values.data.Checklist === 'SI' && fileName == '' ? (
                <div>
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<CloudUploadIcon />}
                      onClick={handleUpload}
                    >
                      <FormattedMessage id="Subir archivo a Amazon" />
                    </Button>
                  </div>
                  <div>
                    <DropzoneDialog
                      showAlerts={false}
                      acceptedFiles={['application/pdf']}
                      cancelButtonText={intl.formatMessage({
                        id: 'Cancel',
                      })}
                      submitButtonText={intl.formatMessage({
                        id: 'Send',
                      })}
                      dialogTitle={intl.formatMessage({
                        id: 'Upload file',
                      })}
                      dropzoneText={intl.formatMessage({
                        id: 'Drag and drop a file here or click',
                      })}
                      maxFileSize={5000000}
                      open={open}
                      onClose={() => setOpen(false)}
                      onSave={handleSave}
                      showPreviews={false}
                      showPreviewsInDropzone={true}
                      showFileNames={true}
                      filesLimit={1}
                    />
                  </div>
                </div>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<GetAppIcon />}
                  onClick={handleDownloadFile}
                >
                  <FormattedMessage id="Descargar certificado glass" />
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Glass;
