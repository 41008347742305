import React from 'react';
import { FormattedMessage } from 'react-intl';
import { EUROPE } from 'enums/regions';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import get from 'lodash/get';

const handleDownloadFile = () => {
  const endUserFileUrl = localStorage.getItem('endUserFileUrl');
  if (endUserFileUrl) {
    window.open(endUserFileUrl, '_blank');
  } else {
    console.error('End user file URL is not available in localStorage.');
  }
};

export const Project = ({ values }) => {
  const isEuropeCountry = EUROPE.includes(values.data.country);
  return (
    <>
      <div className="row">
        <div className="col-12 ViewOrEdit">
          <div>
            <strong>
              <FormattedMessage id="Nombre del proyecto:" />
            </strong>
            <span>{get(values, 'data.name', '-')}</span>
          </div>
          <div className="kt-space-20" />
          <div>
            <strong>
              <FormattedMessage id="Tipo de proyecto:" />
            </strong>
            <span>
              <FormattedMessage id={get(values, 'data.type', '-')} />
            </span>
          </div>
          <div className="kt-space-20" />
          <div>
            <strong>
              <FormattedMessage id="Principal necesidad:" />
            </strong>
            <span>
              <FormattedMessage id={get(values, 'data.need', '-')} />
            </span>
          </div>
          <div className="kt-space-20" />
          <div>
            <strong>
              <FormattedMessage id="Calle:" />
            </strong>
            <span>{get(values, 'data.address', '-')}</span>
          </div>
          <div className="kt-space-20" />
          <div>
            <strong>
              <FormattedMessage id="Número:" />
            </strong>
            <span>{get(values, 'data.number', '-')}</span>
          </div>
          <div className="kt-space-20" />
          <div>
            <strong>
              <FormattedMessage id="Piso:" />
            </strong>
            <span>{get(values, 'data.floor', '-')}</span>
          </div>
          <div className="kt-space-20" />
          <div>
            <strong>
              <FormattedMessage id="Departamento:" />
            </strong>
            <span>{get(values, 'data.department', '-')}</span>
          </div>
          <div className="kt-space-20" />
          <div>
            <strong>
              <FormattedMessage id="Código postal:" />
            </strong>
            <span>{get(values, 'data.postalCode', '-')}</span>
          </div>
          <div className="kt-space-20" />
          <div>
            <strong>
              <FormattedMessage id="Provincia:" />
            </strong>
            <span>{get(values, 'data.state', '-')}</span>
          </div>
          <div className="kt-space-20" />
          {/* {isEuropeCountry && (
            <div>
              <Button
                variant="contained"
                color="primary"
                startIcon={<GetAppIcon />}
                onClick={handleDownloadFile}
              >
                <FormattedMessage id="Descargar certificado end user" />
              </Button>
            </div>
          )} */}
          {/* <div>
          <strong>
            <FormattedMessage id="Ciudad:" />
          </strong>
          <span>{get(values, 'data.city', '-')}</span>
        </div> */}
        </div>
      </div>
    </>
  );
};

export default Project;
